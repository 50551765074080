import React, { useCallback, useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useAsk } from '../contexts/AskContext';
import QuerySection from '../components/Dashboards/QuerySection';
import DashboardManager from '../components/Dashboards/DashboardManager';
import AgentOutputContainer from '../components/AgentOutputContainer';
import TableAccessInfo from '../components/TableAccessInfo';
import { useConfig } from '../contexts/ConfigContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Database, Info } from 'lucide-react';
import PopupCard from '../components/PopupCard';

const USER_NAME_CACHE_KEY = 'user_name_cache';

const Ask = ({ mainContentRef }) => {
  const { currentUser, isAdmin } = useAuth();
  const [userName, setUserName] = useState(() => {
    try {
      const cached = localStorage.getItem(USER_NAME_CACHE_KEY);
      if (cached) {
        const { name, email } = JSON.parse(cached);
        if (email === currentUser?.email) {
          return name;
        }
      }
    } catch (error) {
      console.error('Error reading from localStorage:', error);
    }
    return '';
  });
  const [isNameLoading, setIsNameLoading] = useState(!userName);

  const {
    queryResult,
    isLoading,
    error,
    setError,
    isQueryJustAdded,
    setIsQueryJustAdded,
    handleQueryResult,
    handleQueryStart,
    handleClearResult
  } = useAsk();

  const { config, isLoading: isConfigLoading } = useConfig();
  const [isTableAccessModalOpen, setIsTableAccessModalOpen] = useState(false);


  // Effect to fetch user's name
  useEffect(() => {
    const fetchUserName = async () => {
      if (!currentUser?.email) return;
      
      try {
        setIsNameLoading(true);
        const userPrefsRef = doc(db, 'registeredUsersPreferences', currentUser.email);
        const docSnap = await getDoc(userPrefsRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          const firstName = data.englishName?.split(' ')[0] || '';
          
          try {
            localStorage.setItem(USER_NAME_CACHE_KEY, JSON.stringify({
              name: firstName,
              email: currentUser.email
            }));
          } catch (error) {
            console.error('Error caching to localStorage:', error);
          }
  
          setUserName(firstName);
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      } finally {
        setIsNameLoading(false);
      }
    };
  
    // Only fetch from DB if this is a fresh page load (not a navigation)
    if (performance.navigation.type === 1 || !userName) {
      fetchUserName();
    }
  }, [currentUser]);
  
  const submitQuery = useCallback(
    async (query, isFollowUp = false, highlightedText = '', originalSqlQuery = '') => {
      handleQueryStart();
      try {
        const userId = currentUser?.email || 'anonymous';
        const userQuery = query;

        const response = await fetch(
          'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/processDynamicQueryWithCORS',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              data: {
                userQuery: userQuery,
                userId: userId,
                isFollowUp: isFollowUp,
                isAdmin: isAdmin,
                highlightedText: highlightedText,
                originalSqlQuery: originalSqlQuery,
                originalDocumentId: queryResult ? queryResult.documentId : null,
                originalSavedQueryId: queryResult ? queryResult.savedQueryId : null,
              },
              context: { auth: true },
            }),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error('Failed to process query.');
        }

        const newResult = {
          result: data.result || [],
          sqlQuery: data.sqlQuery || '',
          schema: data.schema || '',
          userQuery: data.userQuery || query,
          truncationMessage: data.truncationMessage || '',
          truncated: data.truncated || false,
          explanation: data.explanation || '',
          isFollowUp: data.isFollowUp || isFollowUp,
          originalQuery: data.originalQuery || '',
          documentId: data.documentId || (queryResult ? queryResult.documentId : null),
          savedQueryId: data.savedQueryId || (queryResult ? queryResult.savedQueryId : null),
          config: {
            usedModel: data.usedModel || 'Unknown',
            fallbackUsed: data.fallbackUsed || false,
          },
          retryCount: data.retryCount || 0,
          retryResult: data.retryResult || 'N/A',
          bigQueryError: data.bigQueryError || null,
          status: data.status || 'completed',
          endTime: data.endTime || new Date().toISOString(),
        };

        handleQueryResult(newResult);
      } catch (error) {
        console.error('Error submitting query:', error);
        setError({
          message: 'An unexpected error occurred. Please try again.',
          status: 'error',
        });
        handleQueryResult(null);
      }
    },
    [currentUser, handleQueryStart, handleQueryResult, queryResult, isAdmin, setError, config]
  );

  const handleFollowUpQuery = useCallback(
    (followUpQuery, highlightedText, originalSqlQuery) => {
      submitQuery(followUpQuery, true, highlightedText, originalSqlQuery);
    },
    [submitQuery]
  );

  const handleSaveQuery = useCallback(async (name, isPrivate = true) => {
    if (!queryResult) return;

    try {
      const response = await fetch(
        'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/saveQuery',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            data: {
              name,
              userQuery: queryResult.userQuery,
              sqlQuery: queryResult.sqlQuery,
              userId: currentUser.email,
              isPrivate,
              documentId: queryResult.documentId,
              explanation: queryResult.explanation,
            },
            context: { auth: true },
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to save query');
      }

      setIsQueryJustAdded(true);
      setTimeout(() => setIsQueryJustAdded(false), 3000);
    } catch (error) {
      console.error('Error saving query:', error);
      setError({
        message: 'Failed to save query. Please try again.',
        status: 'error',
      });
    }
  }, [queryResult, currentUser, setError, setIsQueryJustAdded, config]);

  const handleAddToDashboard = useCallback(async (dashboardId, name) => {
    if (!queryResult) return;
    
    try {
      const savedQueryResponse = await fetch(
        'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/saveQuery',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            data: {
              name,
              userQuery: queryResult.userQuery,
              sqlQuery: queryResult.sqlQuery,
              userId: currentUser.email,
              isPrivate: true,
              documentId: queryResult.documentId,
              explanation: queryResult.explanation,
            },
            context: { auth: true },
          }),
        }
      );

      if (!savedQueryResponse.ok) {
        throw new Error('Failed to save query');
      }

      const savedQueryData = await savedQueryResponse.json();
      
      const dashboardResponse = await fetch(
        'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/handleDashboardOperation',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            data: {
              operation: 'addQuery',
              dashboardId,
              queryId: savedQueryData.queryId,
              userId: currentUser.email,
            },
            context: { auth: true },
          }),
        }
      );

      if (!dashboardResponse.ok) {
        throw new Error('Failed to add query to dashboard');
      }

      setIsQueryJustAdded(true);
      setTimeout(() => setIsQueryJustAdded(false), 3000);
    } catch (error) {
      console.error('Error adding query to dashboard:', error);
      setError({
        message: 'Failed to add query to dashboard. Please try again.',
        status: 'error',
      });
    }
  }, [queryResult, currentUser, setError, setIsQueryJustAdded, config]);

  const handleDashboardUpdate = useCallback(() => {
    // Implement if needed for future dashboard-related functionality
  }, []);

  const dashboardManager = DashboardManager({
    setError,
    handleQueryResult,
    currentUser,
    isAdmin,
  });

  if (!currentUser) {
    return (
      <div className="text-center py-10">
        <h2 className="text-2xl font-bold mb-4">Please log in to view this page</h2>
        <p>You need to be logged in to access the query features.</p>
      </div>
    );
  }

  // Container classes - base container 
  const containerClasses = `
    container mx-auto px-0 sm:px-6 flex flex-col items-center justify-center min-h-[calc(100vh-64px)]
  `;

  // Query container - wider on mobile
  const queryContainerClasses = `
    relative mx-0 sm:mx-auto w-full
    transition-all duration-500 ease-in-out
    ${queryResult 
      ? 'w-full' 
      : 'md:w-[768px] w-full'
    }
  `;

  // Enhanced title classes with smooth transition for name
  const titleClasses = `
    text-2xl md:text-3xl font-bold mb-8 text-center text-gray-700
    transition-all duration-500 ease-in-out transform
    ${queryResult 
      ? 'scale-90 opacity-0 absolute top-0 left-1/2 -translate-x-1/2' 
      : 'scale-100 opacity-100 relative'
    }
  `;

  // Name specific transition classes
  const nameClasses = `
    inline-block
    transition-all duration-300 ease-in-out
    ${isNameLoading ? 'opacity-0 translate-y-1' : 'opacity-100 translate-y-0'}
  `;

  return (
    <div className={containerClasses}>
      <div className={titleClasses}>
        {userName ? (
          <>
            <span className={nameClasses}>{userName}</span>
            <span>, shoot a data question</span>
          </>
        ) : (
          <span>Shoot a question about your data</span>
        )}
      </div>

      <div className={queryContainerClasses}>
        <QuerySection
          onQueryResult={handleQueryResult}
          onQueryStart={handleQueryStart}
          queryResult={queryResult}
          isLoading={isLoading || isConfigLoading}
          error={error}
          currentUser={currentUser}
          onSaveQuery={handleSaveQuery}
          onAddToDashboard={handleAddToDashboard}
          onFollowUpQuery={handleFollowUpQuery}
          mainContentRef={mainContentRef}
          submitQuery={submitQuery}
          onDashboardUpdate={handleDashboardUpdate}
          dashboardManager={dashboardManager}
          isQueryJustAdded={isQueryJustAdded}
          setIsQueryJustAdded={setIsQueryJustAdded}
          isAskPage={true}
          onClearResult={handleClearResult}
        />
        
        {/* Agent output container commented out temporarily
        <div className="mt-6">
          <AgentOutputContainer 
            onClose={(agentId) => {
              console.log('Closed agent output:', agentId);
            }}
            className="mx-auto"
          />
        </div>
        */}
      </div>
    </div>
  );
};

export default Ask;