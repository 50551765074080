// src/pages/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation, Routes, Route, Navigate } from 'react-router-dom';
import PromptManagement from '../components/PromptManagement';
import SchemaManagement from '../components/SchemaManagement';
import UserManagement from '../components/UserManagement';
import AgentManagement from '../components/AgentManagement';
import PolicyManagement from '../components/PolicyManagement';
import RoleManagement from '../components/RoleManagement';
import UserRoleAssignment from '../components/UserRoleAssignment';
import DocsManagement from '../components/Docs/DocsManagement';
import ProposedQuestionsManagement from '../components/ProposedQuestionsManagement';

const AdminDashboard = () => {
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeAccessControl, setActiveAccessControl] = useState('policies');

  // Get the current section from the URL
  const currentPath = location.pathname.split('/').pop();
  
  useEffect(() => {
    // If no section is specified, redirect to prompts
    if (location.pathname === '/admin') {
      navigate('/admin/schema');
    }
  }, [location, navigate]);

  useEffect(() => {
    console.log('AdminDashboard auth state:', { isAdmin });
  }, [isAdmin]);

  const renderAccessControlContent = () => {
    switch (activeAccessControl) {
      case 'policies':
        return <PolicyManagement />;
      case 'roles':
        return <RoleManagement />;
      case 'user-roles':
        return <UserRoleAssignment />;
      default:
        return <PolicyManagement />;
    }
  };

  const getTabClass = (tabName) => {
    const isActive = currentPath === tabName;
    return `pb-4 px-2 -mb-px ${
      isActive
        ? 'border-b-2 border-primary text-primary font-medium'
        : 'text-gray-500 hover:text-gray-700'
    }`;
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="container mx-auto px-2 sm:px-0 py-2 sm:py-4">
        <div className="mb-6">
          <nav className="flex space-x-6 border-b border-gray-200">
            <button 
              className={getTabClass('schema')}
              onClick={() => navigate('/admin/schema')}
            >
              Data
            </button>
            <button 
              className={getTabClass('prompts')}
              onClick={() => navigate('/admin/prompts')}
            >
              Prompt
            </button>
            <button 
              className={getTabClass('proposed-questions')}
              onClick={() => navigate('/admin/proposed-questions')}
            >
              Proposed Questions
            </button>
            <button 
              className={getTabClass('docs')}
              onClick={() => navigate('/admin/docs')}
            >
              Docs
            </button>
            <button 
              className={getTabClass('users')}
              onClick={() => navigate('/admin/users')}
            >
              Users
            </button>
            <button 
              className={getTabClass('agents')}
              onClick={() => navigate('/admin/agents')}
            >
              Agents
            </button>
            <button 
              className={getTabClass('access-control')}
              onClick={() => navigate('/admin/access-control')}
            >
              Access Control
            </button>
          </nav>
        </div>
        
        {/* Main content area */}
        <div className="w-full">
          <Routes>
            <Route path="prompts" element={<PromptManagement />} />
            <Route path="schema" element={<SchemaManagement />} />
            <Route path="proposed-questions" element={<ProposedQuestionsManagement />} />
            <Route path="docs" element={<DocsManagement />} />
            <Route path="users" element={<UserManagement />} />
            <Route path="agents" element={<AgentManagement />} />
            <Route 
              path="access-control" 
              element={
                <div className="space-y-6">
                  <div className="bg-gray-50 rounded-lg p-4">
                    <div className="flex space-x-4 border-b border-gray-200">
                      <button 
                        className={`pb-4 px-2 -mb-px ${
                          activeAccessControl === 'policies' 
                            ? 'border-b-2 border-primary text-primary font-medium'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveAccessControl('policies')}
                      >
                        Policies
                      </button>
                      <button 
                        className={`pb-4 px-2 -mb-px ${
                          activeAccessControl === 'roles' 
                            ? 'border-b-2 border-primary text-primary font-medium'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveAccessControl('roles')}
                      >
                        Roles
                      </button>
                      <button 
                        className={`pb-4 px-2 -mb-px ${
                          activeAccessControl === 'user-roles' 
                            ? 'border-b-2 border-primary text-primary font-medium'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveAccessControl('user-roles')}
                      >
                        User Roles
                      </button>
                    </div>
                    
                    <div className="mt-6">
                      {renderAccessControlContent()}
                    </div>
                  </div>
                </div>
              } 
            />
            <Route path="/" element={<Navigate to="/admin/schema" replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;