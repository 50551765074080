import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { debounce } from 'lodash';
import { useAuth } from '../../contexts/AuthContext';


// Move common words outside component to avoid recreation
const commonWords = new Set([
  'the', 'be', 'to', 'of', 'and', 'a', 'in', 'that', 'have', 'i', 'it', 'for',
  'not', 'on', 'with', 'he', 'as', 'you', 'do', 'at', 'this', 'but', 'his',
  'by', 'from', 'they', 'we', 'say', 'her', 'she', 'or', 'an', 'will', 'my',
  'one', 'all', 'would', 'there', 'their', 'what', 'so', 'up', 'out', 'if',
  'about', 'who', 'get', 'which', 'go', 'me', 'when', 'make', 'can', 'like',
  'time', 'no', 'just', 'him', 'know', 'take', 'people', 'into', 'year', 'your',
  'good', 'some', 'could', 'them', 'see', 'other', 'than', 'then', 'now', 'look',
  'only', 'come', 'its', 'over', 'think', 'also', 'back', 'after', 'use', 'two',
  'how', 'our', 'work', 'first', 'well', 'way', 'even', 'new', 'want', 'because',
  'any', 'these', 'give', 'day', 'most', 'us', 'compare'
]);

const AutoCompleteSearch = ({
  onSelect,
  inputValue,
  cursorPosition,
  minChars = 3,
  onVisibilityChange,
  onSuggestionsChange,
  onSelectedIndexChange,
  isLoading
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { allowedTables } = useAuth();
  const allowedTablesRef = useRef(allowedTables);
  const [lastSelectedPosition, setLastSelectedPosition] = useState({
    start: -1,
    end: -1,
    text: ''
  });
  const listRef = useRef(null);
  const [indexCache, setIndexCache] = useState(null);
  const prevInputRef = useRef(inputValue);

  useEffect(() => {
    if (allowedTables && JSON.stringify(allowedTables) !== JSON.stringify(allowedTablesRef.current)) {
      allowedTablesRef.current = allowedTables;
      // Clear the cache when allowedTables changes
      setIndexCache(null);
      console.log('[AutoComplete] AllowedTables updated, clearing cache');
    }
  }, [allowedTables]);

  // Reset lastSelectedPosition when input is cleared or significantly changed
  useEffect(() => {
    const inputCleared = inputValue.length === 0;
    const significantChange = inputValue !== prevInputRef.current &&
                            !inputValue.includes(lastSelectedPosition.text);
    
    if (inputCleared || significantChange) {
      setLastSelectedPosition({ start: -1, end: -1, text: '' });
    }
    
    prevInputRef.current = inputValue;
  }, [inputValue, lastSelectedPosition.text]);

  useEffect(() => {
    if (onVisibilityChange) {
      onVisibilityChange(suggestions.length > 0);
    }
    if (onSuggestionsChange) {
      onSuggestionsChange(suggestions);
    }
  }, [suggestions, onVisibilityChange, onSuggestionsChange]);
  
  useEffect(() => {
    if (onSelectedIndexChange) {
      onSelectedIndexChange(selectedIndex);
    }
  }, [selectedIndex, onSelectedIndexChange]);

  // Clear suggestions when loading starts
  useEffect(() => {
    if (isLoading) {
      setSuggestions([]);
      setSelectedIndex(-1);
      if (onVisibilityChange) {
        onVisibilityChange(false);
      }
      if (onSuggestionsChange) {
        onSuggestionsChange([]);
      }
      if (onSelectedIndexChange) {
        onSelectedIndexChange(-1);
      }
    }
  }, [isLoading, onVisibilityChange, onSuggestionsChange, onSelectedIndexChange]);

  const getCurrentWord = useCallback((text, position, suggestion = null) => {
    const beforeCursor = text.slice(0, position);
    const lastSpaceIndex = beforeCursor.lastIndexOf(' ');
    const lastWord = beforeCursor.slice(lastSpaceIndex + 1);
    
    // Get the previous word if it exists
    const textBeforeLastWord = beforeCursor.slice(0, lastSpaceIndex);
    const previousSpaceIndex = textBeforeLastWord.lastIndexOf(' ');
    const previousWord = lastSpaceIndex > 0 ? textBeforeLastWord.slice(previousSpaceIndex + 1) : '';
    
    const combinedWords = previousWord ? `${previousWord} ${lastWord}` : lastWord;
    
    if (suggestion) {
      const suggestionWords = suggestion.toLowerCase().split(' ');
      const textWords = beforeCursor.toLowerCase().split(' ');
      let matchStart = textWords.length - 1;
      
      for (let i = textWords.length - 1; i >= 0; i--) {
        const word = textWords[i];
        if (suggestionWords.some(sw => sw.startsWith(word))) {
          matchStart = i;
        } else {
          break;
        }
      }
      
      const startPos = textWords.slice(0, matchStart).join(' ').length;
      return {
        word: lastWord,
        previousWord,
        combinedWords,
        start: matchStart === 0 ? 0 : startPos + 1,
        end: beforeCursor.length,
        hasLPrefix: lastWord.startsWith('ל')
      };
    }
    
    return {
      word: lastWord,
      previousWord,
      combinedWords,
      start: lastSpaceIndex + 1,
      end: beforeCursor.length,
      hasLPrefix: lastWord.startsWith('ל')
    };
}, []);

  const getIndexCache = useCallback(async () => {
    if (indexCache) return indexCache;
    if (!allowedTablesRef.current) {
      console.log('[AutoComplete] No allowed tables available yet');
      return new Map();
    }

    console.log('[AutoComplete] Fetching fresh index cache');
    const indexCollection = collection(db, 'index');
    const snapshot = await getDocs(indexCollection);
    const cache = new Map();
    
    snapshot.docs.forEach(doc => {
      const data = doc.data();
      if (!data.values || !Array.isArray(data.values) || !data.tableId) return;
      
      // Use the ref here
      if (!allowedTablesRef.current[data.tableId]) {
        return;
      }
  
      const tableName = data.tableId.split('.').slice(-1)[0]
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
  
      cache.set(doc.id, {
        values: data.values,
        tableId: data.tableId,
        columnName: data.columnName,
        tableName
      });
    });
  
    console.log('[AutoComplete] Index cache built with entries:', cache.size);
    setIndexCache(cache);
    return cache;
  }, [indexCache]);

  const searchValues = useCallback(async (searchObj, currentWordStart, currentWordEnd) => {
    // Early return conditions
    const { word: currentWord, previousWord, combinedWords } = searchObj;  // Added combinedWords here
    if (!currentWord || 
        (previousWord ? currentWord.length < 2 : currentWord.length < minChars)) {
      setSuggestions([]);
      return;
    }
    
    const currentText = inputValue.slice(currentWordStart, currentWordEnd);
    if (
      (currentWordStart === lastSelectedPosition.start && 
      currentWordEnd === lastSelectedPosition.end &&
      currentText === lastSelectedPosition.text) ||
      (lastSelectedPosition.text && 
      lastSelectedPosition.text.toLowerCase().includes(currentText.toLowerCase()))
    ) {
      setSuggestions([]);
      return;
    }
  
    const cache = await getIndexCache();
    const results = [];
    const seenValues = new Set();
  
    // Create search variations
    const currentWordLower = currentWord.toLowerCase();
    const previousWordLower = previousWord ? previousWord.toLowerCase() : '';
    const combinedWordsLower = combinedWords.toLowerCase();  // Now this will work
  
    // Check if current word starts with 'ל' and create alternative strings
    const hasLPrefix = currentWordLower.startsWith('ל');
    const currentWithoutL = hasLPrefix ? currentWordLower.slice(1) : currentWordLower;

    // Determine search strategy based on word lengths
    const shouldSearchCurrentWord = !previousWordLower && currentWordLower.length >= minChars;
    const shouldSearchCombined = previousWordLower && currentWordLower.length >= 2;
    const shouldIncludeCurrentWord = previousWordLower && currentWordLower.length >= minChars;

    for (const [docId, data] of cache) {
      const tableId = data.tableId;

      // Skip if table is not in allowed tables
      if (!allowedTables || !allowedTables[tableId]) {
        console.log('[AutoComplete] No access to table:', tableId);
        continue;
      }
          
      const matchingValues = data.values
        .filter(item => {
          const key = `${item.value}-${tableId}-${data.columnName}`;
          if (seenValues.has(key)) return false;
          
          const itemLower = item.value.toLowerCase();
          
          // First try combined search if applicable
          if (shouldSearchCombined && itemLower.includes(combinedWordsLower)) {
            seenValues.add(key);
            return true;
          }
          
          // Then try current word alone if conditions are met
          if ((shouldSearchCurrentWord || shouldIncludeCurrentWord) && 
              (itemLower.includes(currentWordLower) || 
              (hasLPrefix && itemLower.includes(currentWithoutL)))) {
            seenValues.add(key);
            return true;
          }
          
          return false;
        })
            .slice(0, 100)
            .map(item => ({
              value: item.value,
              tableId: tableId,
              columnName: data.columnName,
              tableName: data.tableName,
              originalSearch: currentWord
            }));
        
          results.push(...matchingValues);
          if (results.length >= 5) break;
        }
      
        const sortedResults = results
      .sort((a, b) => {
        // First, prioritize exact matches with the current word
        const aStartsWithCurrent = a.value.toLowerCase().startsWith(currentWithoutL);
        const bStartsWithCurrent = b.value.toLowerCase().startsWith(currentWithoutL);
        if (aStartsWithCurrent !== bStartsWithCurrent) return bStartsWithCurrent ? 1 : -1;

        // Then, if there are previous words, prioritize matches with the combined words
        if (previousWordLower) {
          const aMatchesCombined = a.value.toLowerCase().includes(combinedWordsLower);
          const bMatchesCombined = b.value.toLowerCase().includes(combinedWordsLower);
          if (aMatchesCombined !== bMatchesCombined) return bMatchesCombined ? 1 : -1;
        }

        // Finally, sort alphabetically
        return a.value.localeCompare(b.value);
      })
      .slice(0, 5);
  
    setSuggestions(sortedResults);
  }, [minChars, getIndexCache, lastSelectedPosition, inputValue, allowedTables]);

  const debouncedSearch = useMemo(() => 
    debounce(searchValues, 300, { leading: true }), 
    [searchValues]
  );

  useEffect(() => {
    const wordInfo = getCurrentWord(inputValue, cursorPosition);
    debouncedSearch(wordInfo, wordInfo.start, wordInfo.end);
    setSelectedIndex(-1); // Reset when input changes
    return () => debouncedSearch.cancel();
}, [inputValue, cursorPosition, getCurrentWord, debouncedSearch]);

  const handleSelect = useCallback((suggestion) => {
    const { word, start, end, hasLPrefix } = getCurrentWord(inputValue, cursorPosition, suggestion.value);
    
    // If original search had 'ל' prefix but the suggestion doesn't, add it back
    const finalValue = hasLPrefix && !suggestion.value.startsWith('ל') 
      ? `ל${suggestion.value}`
      : suggestion.value;
  
    setLastSelectedPosition({
      start: start,
      end: start + finalValue.length,
      text: finalValue
    });
  
    // Group all occurrences of this value by table
    const valueContext = Array.from(suggestions)
      .filter(s => s.value.toLowerCase() === suggestion.value.toLowerCase())
      .map(s => ({
        value: s.value,
        tableName: s.tableName,
        tableId: s.tableId,
        columnName: s.columnName
      }));
  
    onSelect({
      ...suggestion,
      suggestion: {
        ...suggestion,
        value: finalValue,
        valueContext // Add the context of all tables containing this value
      },
      wordStart: start,
      wordEnd: end,
      replacementText: finalValue
    });
    
    setSuggestions([]);
    setSelectedIndex(-1); // Reset the selected index back to -1
    
    if (onVisibilityChange) {
      onVisibilityChange(false);
    }
  }, [inputValue, cursorPosition, onSelect, getCurrentWord, onVisibilityChange, suggestions]);

  const handleKeyDown = useCallback((e) => {
    if (!suggestions.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => (prev + 1) % suggestions.length);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => (prev - 1 + suggestions.length) % suggestions.length);
        break;
      case 'Enter':
        e.preventDefault();
        handleSelect(suggestions[selectedIndex]);
        break;
      case 'Escape':
        e.preventDefault();
        setSuggestions([]);
        break;
      case 'Backspace':
      case 'Delete':
        setLastSelectedPosition({ start: -1, end: -1, text: '' });
        break;
    }
  }, [suggestions, selectedIndex, handleSelect]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  if (!suggestions.length) return null;

  return (
    <div className="absolute w-1/2 right-0 sm:w-64 sm:right-16 sm:top-0 z-50 mt-1">
      <div className="w-full sm:w-64 bg-gray-900/80 backdrop-blur-sm border border-gray-700/50 rounded-lg shadow-lg animate-fadeIn">
        <ul 
          className="py-1 overflow-y-auto max-h-60 divide-y divide-gray-700/30"
          ref={listRef}
          role="listbox"
        >
          {suggestions.map((suggestion, index) => (
            <li
              key={`${suggestion.value}-${suggestion.tableId}-${suggestion.columnName}`}
              className={`px-3 py-2 cursor-pointer text-sm transition-all duration-150 ${
                index === selectedIndex && selectedIndex !== -1
                ? 'bg-white/10' 
                  : 'hover:bg-white/5'
              }`}
              onClick={() => handleSelect(suggestion)}
              role="option"
              aria-selected={index === selectedIndex}
            >
              <div className={`font-medium ${index === selectedIndex ? 'text-secondary' : 'text-gray-300'}`}>
                {suggestion.value}
              </div>
              <div className="text-xs text-gray-400 mt-0.5">
                {suggestion.columnName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')} • {suggestion.tableName}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(AutoCompleteSearch);