import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const CACHE_KEY = 'proposed_questions_cache';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const ProposedQuestions = ({ onSelectQuestion, visible }) => {
  const [hoveredQuestion, setHoveredQuestion] = useState(null);
  const [questions, setQuestions] = useState(() => {
    // Initialize from localStorage if available
    try {
      const cached = localStorage.getItem(CACHE_KEY);
      if (cached) {
        const { data, timestamp } = JSON.parse(cached);
        // Check if cache is still valid
        if (Date.now() - timestamp < CACHE_DURATION) {
          return data;
        }
      }
    } catch (error) {
      console.error('Error reading from cache:', error);
    }
    return [];
  });
  const [loading, setLoading] = useState(questions.length === 0);

  useEffect(() => {
    const fetchProposedQuestions = async () => {
      // Don't fetch if we already have cached questions
      if (questions.length > 0) {
        return;
      }

      try {
        const docRef = doc(db, 'appConfiguration', 'proposedQuestions');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists() && docSnap.data().questions) {
          const newQuestions = docSnap.data().questions;
          setQuestions(newQuestions);
          
          // Cache the new questions
          try {
            localStorage.setItem(CACHE_KEY, JSON.stringify({
              data: newQuestions,
              timestamp: Date.now()
            }));
          } catch (error) {
            console.error('Error caching questions:', error);
          }
        } else {
          console.warn('No proposed questions found in configuration');
        }
      } catch (error) {
        console.error('Error fetching proposed questions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProposedQuestions();
  }, [questions.length]);

  if (!visible || loading || questions.length === 0) {
    return null;
  }

  return (
    <div className="w-full mb-6 animate-fadeIn">
      <div className="flex flex-wrap gap-2 justify-center">
        {questions.map((question, i) => (
          <button
            key={i}
            className="px-4 py-2 rounded-full text-sm transition-all duration-200
                      bg-gray-700/5 hover:bg-gray-700/10
                      text-gray-700
                      hover:scale-105"
            onMouseEnter={() => setHoveredQuestion(i)}
            onMouseLeave={() => setHoveredQuestion(null)}
            onClick={() => onSelectQuestion(question)}
          >
            {question}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ProposedQuestions;